<template>

<app-content :fullheight="true">

	<app-content-head title="Assistant" :is-app="true" />

	<app-content-body class="scopes">

		<router-link :to="{name: $constants.assistant.scopesRoute[scope]}" class="scopes-item" v-for="scope in scopes" :key="scope">
		
			<app-icon :icon="$constants.assistant.scopesIcon[scope]" class="scopes-item-icon" />
			<div class="scopes-item-text">{{ $constants.assistant.scopes[scope] }}</div>

		</router-link>

	</app-content-body>

</app-content>

</template>

<script>

export default {

	created: function() {

		if (this.scopes.length === 1) {

			this.$router.push({
				name: this.$constants.assistant.scopesRoute[this.scopes[0]]
			})

		}

	},

	computed: {

		scopes: function() {

			return this.$store.getters['session/scope']

		}

	}

}

</script>

<style scoped>

.scopes {
	padding: 20px;
	background-color: #fff;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100px, 128px));
	grid-template-rows: 128px;
	grid-gap: 10px;
}

.is-mobile .scopes {
	grid-template-columns: 1fr 1fr;
}

.scopes-item {
	height: 128px;
	display: flex;
	grid-template-columns: repeat(auto-fill, 128px);
	border-radius: 10px;
	background-color: #4d5b6e;
	color: #fff;
	padding: 10px;
	cursor: pointer;
	transition: all 100ms linear;
}

.scopes-item:nth-child(1) {
	background-color: #61a076;
}

.scopes-item:nth-child(2) {
	background-color: #7b6a90;
}

.scopes-item:nth-child(3) {
	background-color: #e09451;
}

.scopes-item:nth-child(4) {
	background-color: #518ee0;
}

.scopes-item:nth-child(5) {
	background-color: #df7474;
}

.is-desktop .scopes-item:hover {
	transform: scale(1.05);
}

.scopes-item-icon {
	font-size: 40px;
	position: absolute;
	left: 50%;
	top: 32px;
	transform: translateX(-50%);
}

.scopes-item-icon >>> i {
	--fa-primary-color: #ffffff;
	--fa-secondary-color: #ffffff;
	--fa-secondary-opacity: 1;
} 

.scopes-item-text {
	font-weight: 500;
	position: absolute;
	top: 86px;
	font-size: 14px;
	line-height: 16px;
	padding: 0px 10px;
	left: 0px;
	width: 100%;
	text-align: center;
}

</style>
